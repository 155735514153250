import { createMuiTheme } from "@material-ui/core"

const theme = createMuiTheme({
  palette: {
    secondary: { main: "#76ba43" },
    primary: { main: "#056fab", contrastText: "#fff" },
    spacing: 8,
  },
})

export default theme
